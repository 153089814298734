import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { Select, TextInput } from '@imbox/ui-components'
import { setCity, setLabel } from '../../lib/features/settings/settings-slice'
import { cities } from '../weather-widget/weather-widget'
import { useSetUpdated } from '../../hooks/use-set-updated'

export const OtherPropertyPanel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const widgetId = useAppSelector((state) => state.propertyPanel.id)
  const widgetType = useAppSelector((state) => state.propertyPanel.type)
  const city = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.weatherWidgetCity
  )
  const label =
    useAppSelector((state) => state.settings.widgetSettings[widgetId]?.label) ??
    ''

  useSetUpdated([city, label], widgetId)

  const onCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setCity({
        widgetId,
        city: event.target.value,
      })
    )
  }

  const onLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setLabel({
        widgetId,
        label: event.target.value,
      })
    )
  }

  return (
    <>
      {widgetType === 'weatherWidget' ? (
        <Select
          onChange={onCityChange}
          type="Primary"
          value={city}
          label={t('otherPropertyPanel.cityLabel')}
        >
          {Object.keys(cities).map((key) => (
            <option value={key} key={key}>
              {cities[key].name}
            </option>
          ))}
        </Select>
      ) : (
        <TextInput
          onChange={onLabelChange}
          type="Primary"
          value={label}
          label={t('otherPropertyPanel.label')}
          placeholder={t('otherPropertyPanel.labelPlaceholder')}
        />
      )}
    </>
  )
}
