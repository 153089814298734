import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography } from '@imbox/ui-components'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
} from '../widget-base-styles'
import {
  WeatherWidgetContainer,
  WeatherWidgetHeader,
  WeatherWidgetInner,
} from './weather-widget-styles'
import { SingleDataPointWidgetTitle } from '../single-data-point-widget/single-data-point-widget-styles'
import { useAppSelector } from '../../hooks/redux-hooks'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const cities: Record<
  string,
  { name: string; lat: string; lon: string }
> = {
  got: {
    name: 'Göteborg',
    lat: '57.707',
    lon: '11.967',
  },
  sthlm: {
    name: 'Stockholm',
    lat: '59.329',
    lon: '18.069',
  },
  malmo: {
    name: 'Malmö',
    lat: '55.606',
    lon: '13.001',
  },
  uppsala: {
    name: 'Uppsala',
    lat: '59.859',
    lon: '17.639',
  },
  sollentuna: {
    name: 'Sollentuna',
    lat: '59.428',
    lon: '17.951',
  },
  vasteras: {
    name: 'Västerås',
    lat: '59.616',
    lon: '16.553',
  },
  orebro: {
    name: 'Örebro',
    lat: '59.274',
    lon: '15.207',
  },
  linkoping: {
    name: 'Linköping',
    lat: '58.411',
    lon: '15.622',
  },
  helsingborg: {
    name: 'Helsingborg',
    lat: '56.047',
    lon: '12.694',
  },
  jonkoping: {
    name: 'Jönköping',
    lat: '57.781',
    lon: '14.156',
  },
}

export const WeatherWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const activeCity = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.weatherWidgetCity
    ) ?? 'got'

    const [error, setError] = useState(false)
    const [temperature, setTemperature] = useState(0)
    const [iconName, setIconName] = useState('')
    const [cityName, setCityName] = useState('')
    const [lat, setLat] = useState('')
    const [lon, setLon] = useState('')

    useEffect(() => {
      setCityName(cities[activeCity].name)
      setLat(cities[activeCity].lat)
      setLon(cities[activeCity].lon)
    }, [activeCity])

    useEffect(() => {
      if (!!lat && !!lon) {
        const url = `https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${lat}&lon=${lon}`

        const fetchWeatherData = async () => {
          try {
            const response = await fetch(url)
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            const data = await response.json()
            setTemperature(
              Math.round(
                data.properties.timeseries[0].data.instant.details
                  .air_temperature
              )
            )
            setIconName(
              data.properties.timeseries[0].data.next_1_hours.summary
                .symbol_code
            )
          } catch (err) {
            setError(true)
            console.error(err)
            Sentry.captureException(err)
          }
        }
        fetchWeatherData()
      }
    }, [lat, lon])

    return (
      <WeatherWidgetContainer editMode={editMode} isActive={isActive} ref={ref} {...props}>
        <>
          {editMode && label && (
            <WidgetLabelContainer>
              <Typography type="BodySmallRegular">{label}</Typography>
            </WidgetLabelContainer>
          )}
          {onRemove && (
            <WidgetRemoveContainer>
              <IconButton size="small" onClick={onRemove} type="Secondary">
                close
              </IconButton>
            </WidgetRemoveContainer>
          )}
          <WeatherWidgetInner>
            {error && (
              <Typography type="BodyLargeSemiBold">
                <SingleDataPointWidgetTitle>
                  {t('general.errorMessage')}
                </SingleDataPointWidgetTitle>
              </Typography>
            )}
            {!!iconName && (
              <img src={`/icons/${iconName}.png`} alt={iconName} />
            )}
            {!!temperature && (
              <Typography type="Heading1">
                <WeatherWidgetHeader>
                  {`${temperature}\u00b0`}
                </WeatherWidgetHeader>
              </Typography>
            )}
          </WeatherWidgetInner>
          <Typography type="BodyLargeSemiBold">
            <SingleDataPointWidgetTitle>{cityName}</SingleDataPointWidgetTitle>
          </Typography>
        </>
      </WeatherWidgetContainer>
    )
  }
)
