import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../root-reducer'
import { getDataPointsAsync } from './data-points-api'

type Status = {
  fetch: 'success' | 'error' | 'loading' | 'aborted' | 'idle'
  errorMessage?: string
  lastUpdated?: string
}

export type Call = {
  avgTalkTime: number
  avgWaitTime: number
  incoming: number
  maxWaitTime: number
  outgoing: number
  total: number
  totalAbandoned: number
  totalIncoming: number
  totalMissed: number
  totalOutgoing: number
  waiting: number
  waitTimes: Array<number>
  queues?: Array<{
    name: string
    waiting: number
    incoming: number
    outgoing: number
    total: number
    totalAbandoned: number
    totalIncoming: number
    totalOutgoing: number
    totalMissed: number
    avgTalkTime: number
    avgWaitTime: number
    maxWaitTime: number
    waitTimes: Array<number>
  }>
}

type TicketIncomingData = {
  count: number
  type: {
    email: number
    webform: number
    chat: number
    api: number
    client: number
  }
  status: {
    new: number
    closed: number
    pending: number
    solved: number
    reply: number
    open: number
  }
}

type TicketIncomingDataWithCategories = TicketIncomingData & {
  categories: Record<string, TicketIncomingData>
}

type TicketStatusChangesData = {
  total: number
  completed: number
  completedWithOneAnswer: number
  fcr: number
}

type TicketStatusChangesDataWithCategories = TicketStatusChangesData & {
  categories: Record<string, TicketStatusChangesData>
}

type TicketsCountWithCategories = {
  count: number
  categories: Record<string, { count: number }>
}

export type Chat = {
  agentsOnline: number
  agentStatisticsByGroup: Record<
    string,
    {
      activeChats: number
      agentsOnline: number
      availableSlots: number
      totalSlots: number
    }
  >
  activeChats: number
  servedChats: number
  availableSlots: number
  totalSlots: number
  avgWaitTime: number
  avgChatTime: number
  maxWaitTime: number
  waitTimes: Array<number>
  missed: {
    total: number
    domains: string[]
  }
  labels: {
    total: number
    tags: {
      count: number
      tag: string
    }[]
  }
  pageFilters: Record<
    string,
    {
      activeChats: number
      avgChatTime: number
      avgWaitTime: number
      labels: {
        total: number
        tags: {
          count: number
          tag: string
        }[]
      }
      maxWaitTime: number
      missed: {
        total: number
        domains: string[]
      }
      servedChats: number
      totalChatTime: number
      totalWaitTime: number
      waitTimes: Array<number>
    }
  >
}

export type Points = {
  calls: {
    live: Call
    previous: Call
  }
  tickets: {
    incomingTickets: {
      live: TicketIncomingDataWithCategories
      previous: TicketIncomingDataWithCategories
    }
    statusChanges: {
      live: TicketStatusChangesDataWithCategories
      previous: TicketStatusChangesDataWithCategories
    }
    unsolvedTickets: TicketsCountWithCategories
    servedTickets: {
      live: TicketsCountWithCategories
      previous: TicketsCountWithCategories
    }
  }
  chats: {
    live: Chat
    previous: Chat
  }
}

type DataPointsState = {
  points: Points
  status: Status
}

const incomingTicketsInitialData: TicketIncomingDataWithCategories = {
  count: 0,
  type: {
    email: 0,
    webform: 0,
    chat: 0,
    api: 0,
    client: 0,
  },
  status: {
    new: 0,
    closed: 0,
    pending: 0,
    solved: 0,
    reply: 0,
    open: 0,
  },
  categories: {},
}

const statusChangesTicketsInitialData: TicketStatusChangesDataWithCategories = {
  total: 0,
  completed: 0,
  completedWithOneAnswer: 0,
  fcr: 0,
  categories: {},
}

export const dataPointsSliceInitialState: DataPointsState = {
  points: {
    calls: {
      live: {
        avgTalkTime: 0,
        avgWaitTime: 0,
        incoming: 0,
        maxWaitTime: 0,
        outgoing: 0,
        total: 0,
        totalAbandoned: 0,
        totalIncoming: 0,
        totalMissed: 0,
        totalOutgoing: 0,
        waiting: 0,
        waitTimes: [],
        queues: [],
      },
      previous: {
        avgTalkTime: 0,
        avgWaitTime: 0,
        incoming: 0,
        maxWaitTime: 0,
        outgoing: 0,
        total: 0,
        totalAbandoned: 0,
        totalIncoming: 0,
        totalMissed: 0,
        totalOutgoing: 0,
        waiting: 0,
        waitTimes: [],
        queues: [],
      },
    },
    tickets: {
      incomingTickets: {
        live: incomingTicketsInitialData,
        previous: incomingTicketsInitialData,
      },
      statusChanges: {
        live: statusChangesTicketsInitialData,
        previous: statusChangesTicketsInitialData,
      },
      unsolvedTickets: { count: 0, categories: {} },
      servedTickets: {
        live: {
          count: 0,
          categories: {},
        },
        previous: {
          count: 0,
          categories: {},
        },
      },
    },
    chats: {
      live: {
        agentsOnline: 0,
        agentStatisticsByGroup: {},
        activeChats: 0,
        servedChats: 0,
        availableSlots: 0,
        totalSlots: 0,
        avgWaitTime: 0,
        avgChatTime: 0,
        maxWaitTime: 0,
        waitTimes: [],
        missed: {
          total: 0,
          domains: [],
        },
        labels: {
          total: 0,
          tags: [],
        },
        pageFilters: {},
      },
      previous: {
        agentsOnline: 0,
        agentStatisticsByGroup: {},
        activeChats: 0,
        servedChats: 0,
        availableSlots: 0,
        totalSlots: 0,
        avgWaitTime: 0,
        avgChatTime: 0,
        maxWaitTime: 0,
        waitTimes: [],
        missed: {
          total: 0,
          domains: [],
        },
        labels: {
          total: 0,
          tags: [],
        },
        pageFilters: {},
      },
    },
  },
  status: {
    fetch: 'idle',
  },
}

export const dataPointsSlice = createSlice({
  name: 'dataPoints',
  initialState: dataPointsSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataPointsAsync.pending, (state) => {
        state.status.fetch = 'loading'
      })
      .addCase(getDataPointsAsync.fulfilled, (state, action) => {
        state.status.fetch = 'success'
        state.status.lastUpdated = new Date().toISOString()
        if (action.payload.calls) {
          state.points.calls = action.payload.calls
        }
        if (action.payload.tickets) {
          state.points.tickets = action.payload.tickets
        }
        if (action.payload.chats) {
          state.points.chats = action.payload.chats
        }
      })
      .addCase(getDataPointsAsync.rejected, (state, action) => {
        if (action.meta.aborted) {
          state.status.fetch = 'aborted'
        } else {
          state.status.fetch = 'error'
        }
      })
  },
})

// PHONE

// phone single
export const selectDataPointCallsTotalIncoming = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      live +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.totalIncoming ?? 0
      previous +=
        state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.totalIncoming ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.totalIncoming,
    previous: state.dataPoints.points.calls.previous.totalIncoming,
  }
}

export const selectDataPointCallsTotalOutgoing = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      live +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.totalOutgoing ?? 0
      previous +=
        state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.totalOutgoing ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.totalOutgoing,
    previous: state.dataPoints.points.calls.previous.totalOutgoing,
  }
}

export const selectDataPointCallsTotalAbandoned = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      live +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.totalAbandoned ?? 0
      previous +=
        state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.totalAbandoned ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.totalAbandoned,
    previous: state.dataPoints.points.calls.previous.totalAbandoned,
  }
}

export const selectDataPointCallsTotalMissed = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      live +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.totalMissed ?? 0
      previous +=
        state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.totalMissed ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.totalMissed,
    previous: state.dataPoints.points.calls.previous.totalMissed,
  }
}

export const selectDataPointCallsTotal = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      live +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.total ?? 0
      previous +=
        state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.total ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.total,
    previous: state.dataPoints.points.calls.previous.total,
  }
}

export const selectDataPointCallsWaitTimes = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    const live: number[] = []
    const previous: number[] = []
    queues.forEach((queue) => {
      live.push(
        ...(state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.waitTimes ?? [])
      )
      previous.push(
        ...(state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.waitTimes ?? [])
      )
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.waitTimes,
    previous: state.dataPoints.points.calls.previous.waitTimes,
  }
}

// phone single + call times widget
export const selectDataPointCallsAvgWaitTime = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    const liveDataPoints: number[] = []
    const previosDataPoints: number[] = []

    queues.forEach((queue) => {
      const liveValue = state.dataPoints.points.calls.live.queues?.find((q) => {
        return q.name === queue.value
      })?.avgWaitTime ?? 0
      if (liveValue) {
        liveDataPoints.push(liveValue)
      }

      const previousValue = state.dataPoints.points.calls.previous.queues?.find((q) => {
        return q.name === queue.value
      })?.avgWaitTime ?? 0
      if (previousValue) {
        previosDataPoints.push(previousValue)
      }
    })

    const live = liveDataPoints.length === 0
      ? 0
      : liveDataPoints.reduce((acc, cur) => acc + cur, 0) / liveDataPoints.length

    const previous = previosDataPoints.length === 0
      ? 0
      : previosDataPoints.reduce((acc, cur) => acc + cur, 0) / previosDataPoints.length

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.avgWaitTime,
    previous: state.dataPoints.points.calls.previous.avgWaitTime,
  }
}
export const selectDataPointCallsAvgTalkTime = (
  state: RootState,
  id: string
) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    const liveDataPoints: number[] = []
    const previosDataPoints: number[] = []

    queues.forEach((queue) => {
      const liveValue = state.dataPoints.points.calls.live.queues?.find((q) => {
        return q.name === queue.value
      })?.avgTalkTime ?? 0
      if (liveValue) {
        liveDataPoints.push(liveValue)
      }

      const previousValue = state.dataPoints.points.calls.previous.queues?.find((q) => {
        return q.name === queue.value
      })?.avgTalkTime ?? 0
      if (previousValue) {
        previosDataPoints.push(previousValue)
      }
    })

    const live = liveDataPoints.length === 0
      ? 0
      : liveDataPoints.reduce((acc, cur) => acc + cur, 0) / liveDataPoints.length

    const previous = previosDataPoints.length === 0
      ? 0
      : previosDataPoints.reduce((acc, cur) => acc + cur, 0) / previosDataPoints.length

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.avgTalkTime,
    previous: state.dataPoints.points.calls.previous.avgTalkTime,
  }
}
export const selectDataPointMaxWaitTime = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {

    let live = 0
    let previous = 0
    queues.forEach((queue) => {
      const liveValue = state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.maxWaitTime ?? 0
      const previousValue = state.dataPoints.points.calls.previous.queues?.find((q) => {
          return q.name === queue.value
        })?.maxWaitTime ?? 0

      if (liveValue > live) {
        live = liveValue
      }

      if (previousValue > previous) {
        previous = previousValue
      }
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.calls.live.maxWaitTime,
    previous: state.dataPoints.points.calls.previous.maxWaitTime,
  }
}

// phone call queues table
export const selectDataPointCallsOutgoing = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let outgoing = 0
    queues.forEach((queue) => {
      outgoing +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.outgoing ?? 0
    })

    return outgoing
  }
  return state.dataPoints.points.calls.live.outgoing
}

export const selectDataPointCallsIncoming = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let incoming = 0
    queues.forEach((queue) => {
      incoming +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.incoming ?? 0
    })

    return incoming
  }
  return state.dataPoints.points.calls.live.incoming
}

export const selectDataPointCallsWaiting = (state: RootState, id: string) => {
  const queues = state.settings.widgetSettings[id]?.queues
  if (queues && queues.length > 0) {
    let waiting = 0
    queues.forEach((queue) => {
      waiting +=
        state.dataPoints.points.calls.live.queues?.find((q) => {
          return q.name === queue.value
        })?.waiting ?? 0
    })

    return waiting
  }
  return state.dataPoints.points.calls.live.waiting
}

export const selectDataPointCallQueues = (state: RootState) => {
  return {
    live: state.dataPoints.points.calls?.live.queues ?? [],
    previous: state.dataPoints.points.calls?.previous.queues ?? [],
  }
}

// CHAT

// chat table
export const selectDataPointChatLabels = (state: RootState, id: string) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    const labels: RootState['dataPoints']['points']['chats']['live']['labels'] =
      {
        total: 0,
        tags: [],
      }

    pageFilters.forEach((pageFilter) => {
      labels.total +=
        state.dataPoints.points.chats.live.pageFilters[
          pageFilter.value
        ]?.labels?.total
      labels.tags.push(
        ...(state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
          ?.labels?.tags ?? [])
      )
    })

    return labels
  }
  return state.dataPoints.points.chats.live.labels
}

// chat single
export const selectDataPointChatMissed = (state: RootState, id: string) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    let live = 0
    let previous = 0
    pageFilters.forEach((pageFilter) => {
      live +=
        state.dataPoints.points.chats.live.pageFilters[pageFilter.value]?.missed
          .total ?? 0
      previous +=
        state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
          ?.missed.total ?? 0
    })

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.missed.total,
    previous: state.dataPoints.points.chats.previous.missed.total,
  }
}

export const selectDataPointChatAnswered = (state: RootState, id: string) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    let live = 0
    let previous = 0
    pageFilters.forEach((pageFilter) => {
      live +=
        state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
          ?.servedChats ?? 0
      previous +=
        state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
          ?.servedChats ?? 0
    })

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.servedChats,
    previous: state.dataPoints.points.chats.previous.servedChats,
  }
}

export const selectDataPointChatTotal = (state: RootState, id: string) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    let live = 0
    let previous = 0
    pageFilters.forEach((pageFilter) => {
      live +=
        (state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
          ?.missed.total ?? 0) +
        (state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
          ?.servedChats ?? 0)
      previous +=
        (state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
          ?.missed.total ?? 0) +
        (state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
          ?.servedChats ?? 0)
    })

    return {
      live,
      previous,
    }
  }
  return {
    live:
      state.dataPoints.points.chats.live.missed.total +
      state.dataPoints.points.chats.live.servedChats,
    previous:
      state.dataPoints.points.chats.previous.missed.total +
      state.dataPoints.points.chats.previous.servedChats,
  }
}

export const selectDataPointChatWaitTimes = (state: RootState, id: string) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    const live: number[] = []
    const previous: number[] = []
    pageFilters.forEach((pageFilter) => {
      live.push(
        ...(state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
          ?.waitTimes ?? [])
      )
      previous.push(
        ...(state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
          ?.waitTimes ?? [])
      )
    })

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.waitTimes,
    previous: state.dataPoints.points.chats.previous.waitTimes,
  }
}

// chat single + chat times
export const selectDataPointChatAvgWaitTime = (
  state: RootState,
  id: string
) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    const liveDataPoints: number[] = []
    const previosDataPoints: number[] = []

    pageFilters.forEach((pageFilter) => {
      const liveValue = state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
        ?.avgWaitTime ?? 0

      if (liveValue) {
        liveDataPoints.push(liveValue)
      }

      const previousValue = state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
        ?.avgWaitTime ?? 0

      if (previousValue) {
        previosDataPoints.push(previousValue)
      }
    })

    const live = liveDataPoints.length === 0
      ? 0
      : liveDataPoints.reduce((acc, cur) => acc + cur, 0) / liveDataPoints.length

    const previous = previosDataPoints.length === 0
      ? 0
      : previosDataPoints.reduce((acc, cur) => acc + cur, 0) / previosDataPoints.length

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.avgWaitTime,
    previous: state.dataPoints.points.chats.previous.avgWaitTime,
  }
}

export const selectDataPointChatAvgChatTime = (
  state: RootState,
  id: string
) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    const liveDataPoints: number[] = []
    const previosDataPoints: number[] = []

    pageFilters.forEach((pageFilter) => {
      const liveValue = state.dataPoints.points.chats.live.pageFilters[pageFilter.value]
        ?.avgChatTime ?? 0

      if (liveValue) {
        liveDataPoints.push(liveValue)
      }

      const previousValue = state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]
        ?.avgChatTime ?? 0

      if (previousValue) {
        previosDataPoints.push(previousValue)
      }
    })

    const live = liveDataPoints.length === 0
      ? 0
      : liveDataPoints.reduce((acc, cur) => acc + cur, 0) / liveDataPoints.length

    const previous = previosDataPoints.length === 0
      ? 0
      : previosDataPoints.reduce((acc, cur) => acc + cur, 0) / previosDataPoints.length

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.avgChatTime,
    previous: state.dataPoints.points.chats.previous.avgChatTime,
  }
}

export const selectDataPointChatMaxWaitTime = (
  state: RootState,
  id: string
) => {
  const pageFilters = state.settings.widgetSettings[id]?.pageFilters
  if (pageFilters && pageFilters?.length > 0) {
    let live = 0
    let previous = 0
    pageFilters.forEach((pageFilter) => {
      const liveValue = state.dataPoints.points.chats.live.pageFilters[pageFilter.value]?.maxWaitTime ?? 0
      const previousValue = state.dataPoints.points.chats.previous.pageFilters[pageFilter.value]?.maxWaitTime ?? 0

      if (liveValue > live) {
        live = liveValue
      }

      if (previousValue > previous) {
        previous = previousValue
      }
    })

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.maxWaitTime,
    previous: state.dataPoints.points.chats.previous.maxWaitTime,
  }
}

// chat splitted stats
export const selectDataPointChatAgentsOnline = (
  state: RootState,
  id: string
) => {
  const groups = state.settings.widgetSettings[id]?.groups
  if (groups && groups.length > 0) {
    let agentsOnline = 0
    groups.forEach((group) => {
      agentsOnline +=
        state.dataPoints.points.chats.live.agentStatisticsByGroup[group.value]
          ?.agentsOnline ?? 0
    })

    return agentsOnline
  }
  return state.dataPoints.points.chats.live.agentsOnline
}

export const selectDataPointChatActiveChats = (
  state: RootState,
  id: string
) => {
  const groups = state.settings.widgetSettings[id]?.groups
  if (groups && groups.length > 0) {
    let live = 0
    let previous = 0
    groups.forEach((group) => {
      live +=
        state.dataPoints.points.chats.live.agentStatisticsByGroup[group.value]
          ?.activeChats ?? 0
      previous +=
        state.dataPoints.points.chats.previous.agentStatisticsByGroup[
          group.value
        ]?.activeChats ?? 0
    })

    return {
      live,
      previous,
    }
  }
  return {
    live: state.dataPoints.points.chats.live.activeChats,
    previous: state.dataPoints.points.chats.previous.activeChats,
  }
}

export const selectDataPointChatTotalSlots = (state: RootState, id: string) => {
  const groups = state.settings.widgetSettings[id]?.groups
  if (groups && groups.length > 0) {
    let totalSlots = 0
    groups.forEach((group) => {
      totalSlots +=
        state.dataPoints.points.chats.live.agentStatisticsByGroup[group.value]
          ?.totalSlots ?? 0
    })

    return totalSlots
  }
  return state.dataPoints.points.chats.live.totalSlots
}

// TICKETS

// tickets by channel
export const selectDataPointTicketTypes = (state: RootState, id: string) => {
  const ticketCategories = state.settings.widgetSettings[id]?.ticketCategoryIds
  if (ticketCategories && ticketCategories.length > 0) {
    const type: RootState['dataPoints']['points']['tickets']['incomingTickets']['live']['type'] =
      {
        email: 0,
        webform: 0,
        chat: 0,
        api: 0,
        client: 0,
      }

    ticketCategories.forEach((ticketCategory) => {
      type.email +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.type.email ?? 0
      type.webform +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.type.webform ?? 0
      type.chat +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.type.chat ?? 0
      type.api +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.type.api ?? 0
      type.client +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.type.client ?? 0
    })

    return type
  }

  return state.dataPoints.points.tickets.incomingTickets.live.type
}

// tickets single
export const selectDataPointTicketIncoming = (state: RootState, id: string) => {
  const ticketCategories = state.settings.widgetSettings[id]?.ticketCategoryIds
  if (ticketCategories && ticketCategories.length > 0) {
    let live = 0
    let previous = 0
    ticketCategories.forEach((ticketCategory) => {
      live +=
        state.dataPoints.points.tickets.incomingTickets.live.categories[
          ticketCategory.value
        ]?.count ?? 0
      previous +=
        state.dataPoints.points.tickets.incomingTickets.previous.categories[
          ticketCategory.value
        ]?.count ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.tickets.incomingTickets.live.count,
    previous: state.dataPoints.points.tickets.incomingTickets.previous.count,
  }
}

export const selectDataPointTicketUnsolved = (state: RootState, id: string) => {
  const ticketCategories = state.settings.widgetSettings[id]?.ticketCategoryIds
  if (ticketCategories && ticketCategories.length > 0) {
    let live = 0
    ticketCategories.forEach((ticketCategory) => {
      live +=
        state.dataPoints.points.tickets.unsolvedTickets.categories[
          ticketCategory.value
        ]?.count ?? 0
    })

    return {
      live,
      previous: 0,
    }
  }

  return {
    live: state.dataPoints.points.tickets.unsolvedTickets.count,
    previous: 0,
  }
}

export const selectDataPointTicketServed = (state: RootState, id: string) => {
  const ticketCategories = state.settings.widgetSettings[id]?.ticketCategoryIds
  if (ticketCategories && ticketCategories.length > 0) {
    let live = 0
    let previous = 0
    ticketCategories.forEach((ticketCategory) => {
      live +=
        state.dataPoints.points.tickets.servedTickets.live.categories[
          ticketCategory.value
        ]?.count ?? 0
      previous +=
        state.dataPoints.points.tickets.servedTickets.previous.categories[
          ticketCategory.value
        ]?.count ?? 0
    })

    return {
      live,
      previous,
    }
  }

  return {
    live: state.dataPoints.points.tickets.servedTickets.live.count,
    previous: state.dataPoints.points.tickets.servedTickets.previous.count,
  }
}
